var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-modal",
    {
      attrs: {
        maskClosable: false,
        dialogClass: "translate-modal",
        visible: _vm.visible,
      },
      on: { ok: _vm.handleOk, cancel: _vm.handleCancel },
    },
    [
      _c(
        "div",
        {
          attrs: { slot: "closeIcon" },
          on: { mouseover: _vm.topCenterMouseover },
          slot: "closeIcon",
        },
        [_c("a-icon", { attrs: { type: "close" } })],
        1
      ),
      _c(
        "div",
        {
          staticClass: "modal-title",
          attrs: { slot: "title" },
          on: { mouseover: _vm.topCenterMouseover },
          slot: "title",
        },
        [
          _vm._v(
            "\n    " + _vm._s(_vm.isEdit ? "编辑背景册" : "查看背景册") + "\n  "
          ),
        ]
      ),
      _vm.isEdit
        ? _c(
            "div",
            {
              staticClass: "back-content",
              style: _vm.backCss,
              on: { mouseover: _vm.topCenterMouseover },
            },
            [
              _vm.visible
                ? _c(
                    "a-form-model",
                    _vm._b(
                      {
                        ref: "form",
                        staticStyle: { "padding-bottom": "15px" },
                        attrs: { model: _vm.formData, rules: _vm.rules },
                      },
                      "a-form-model",
                      _vm.layout,
                      false
                    ),
                    [
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "章节序号",
                            prop: "chapterNo",
                          },
                        },
                        [
                          _c(
                            "a-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                disabled: true,
                                "option-filter-prop": "label",
                                "show-search": "",
                                placeholder: "请选择章节",
                              },
                              on: { change: _vm.chapterChange },
                              model: {
                                value: _vm.formData.chapterNo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.formData, "chapterNo", $$v)
                                },
                                expression: "formData.chapterNo",
                              },
                            },
                            [
                              _vm._l(_vm.chapterList, function (chapter) {
                                return _c(
                                  "a-select-option",
                                  {
                                    key: chapter.sequenceNo,
                                    attrs: { value: chapter.sequenceNo },
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(chapter.sequenceNo) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              }),
                              _vm.chapterList.every(
                                (chapter) =>
                                  chapter.sequenceNo != _vm.formData.chapterNo
                              ) && _vm.formData.chapterNo
                                ? _c(
                                    "a-select-option",
                                    {
                                      attrs: { value: _vm.formData.chapterNo },
                                    },
                                    [
                                      _vm._v(
                                        "\n            章节已删除\n          "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c("h4", { staticStyle: { "font-weight": "700" } }, [
                        _vm._v("翻译、校对、终审填写："),
                      ]),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "文本框",
                            prop: "chapterNo",
                          },
                        },
                        [
                          _c(
                            "a-spin",
                            { attrs: { spinning: _vm.selectLoading } },
                            [
                              _c(
                                "a-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    disabled:
                                      _vm.taskDetail.stageName == "制作" ||
                                      _vm.taskDetail.stageName == "监制",
                                    dropdownClassName: "textArea-select",
                                    "show-search": "",
                                    "option-filter-prop": "label",
                                    placeholder: "请选择文本框",
                                  },
                                  on: { change: _vm.textAreaChange },
                                  model: {
                                    value: _vm.formData.textAreaId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "textAreaId", $$v)
                                    },
                                    expression: "formData.textAreaId",
                                  },
                                },
                                _vm._l(_vm.textAreaList, function (textArea) {
                                  return _c(
                                    "a-select-option",
                                    {
                                      key: textArea.id,
                                      attrs: {
                                        label: textArea.label,
                                        value: textArea.id,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(textArea.label) +
                                          "\n            "
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          staticClass: "origin-item",
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "原文图片",
                            prop: "originImgUrl",
                          },
                        },
                        [
                          _c(
                            "a-upload-dragger",
                            {
                              attrs: {
                                name: "file",
                                accept: ".jpg,.png",
                                showUploadList: false,
                                disabled:
                                  _vm.taskDetail.stageName == "制作" ||
                                  _vm.taskDetail.stageName == "监制",
                                action: _vm.ossUploadUrl,
                                data: _vm.ossUploadData,
                                "custom-request": _vm.handleUpload,
                              },
                              on: { change: _vm.handleUploadChange },
                            },
                            [
                              !_vm.formData.originImgUrl
                                ? _c("div", [
                                    _c(
                                      "p",
                                      { staticClass: "ant-upload-drag-icon" },
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "inbox" },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "ant-upload-text" },
                                      [
                                        _vm._v(
                                          "\n              拖拽或点击上传\n            "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "ant-upload-hint" },
                                      [
                                        _vm._v(
                                          "\n              支持JPG、PNG\n            "
                                        ),
                                      ]
                                    ),
                                  ])
                                : _c("img", {
                                    staticStyle: {
                                      "max-width": "300px",
                                      "max-height": "117px",
                                    },
                                    attrs: {
                                      src: _vm.formData.originImgUrl,
                                      alt: "",
                                    },
                                  }),
                              _vm.formData.originImgUrl &&
                              _vm.taskDetail.stageName != "制作" &&
                              _vm.taskDetail.stageName != "监制"
                                ? _c("a-icon", {
                                    attrs: { type: "close-circle" },
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.clearUrl.apply(
                                          null,
                                          arguments
                                        )
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm.visible &&
                          _vm.taskDetail.stageName != "制作" &&
                          _vm.taskDetail.stageName != "监制"
                            ? _c("Editor", {
                                ref: "editor",
                                staticClass: "img-editor",
                                attrs: {
                                  defaultConfig: _vm.editorConfig,
                                  mode: _vm.mode,
                                },
                                on: {
                                  onCreated: _vm.onCreated,
                                  customPaste: _vm.customPaste,
                                },
                                model: {
                                  value: _vm.originImgUrl,
                                  callback: function ($$v) {
                                    _vm.originImgUrl = $$v
                                  },
                                  expression: "originImgUrl",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "原文",
                            prop: "originContent",
                          },
                        },
                        [
                          _c("a-textarea", {
                            attrs: {
                              disabled: true,
                              autosize: { minRows: 1, maxRows: 5 },
                            },
                            model: {
                              value: _vm.formData.originContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "originContent", $$v)
                              },
                              expression: "formData.originContent",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            labelCol: _vm.labelCol,
                            wrapperCol: _vm.wrapperCol,
                            label: "译文",
                            prop: "processedContent",
                          },
                        },
                        [
                          _c("a-textarea", {
                            attrs: {
                              disabled: true,
                              autosize: { minRows: 1, maxRows: 5 },
                            },
                            model: {
                              value: _vm.formData.processedContent,
                              callback: function ($$v) {
                                _vm.$set(_vm.formData, "processedContent", $$v)
                              },
                              expression: "formData.processedContent",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.taskDetail.stageName == "制作" ||
                      _vm.taskDetail.stageName == "监制"
                        ? _c("h4", { staticStyle: { "font-weight": "700" } }, [
                            _vm._v("\n        制作、监制填写：\n      "),
                          ])
                        : _vm._e(),
                      _vm.taskDetail.stageName == "制作" ||
                      _vm.taskDetail.stageName == "监制"
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                labelCol: _vm.labelCol,
                                wrapperCol: _vm.wrapperCol,
                                label: "文件名",
                                prop: "fileName",
                              },
                            },
                            [
                              _c("a-input", {
                                attrs: { placeholder: "请输入文件名" },
                                model: {
                                  value: _vm.formData.fileName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "fileName", $$v)
                                  },
                                  expression: "formData.fileName",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.taskDetail.stageName == "制作" ||
                      _vm.taskDetail.stageName == "监制"
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                labelCol: _vm.labelCol,
                                wrapperCol: _vm.wrapperCol,
                                label: "制作后图片",
                                prop: "chapterNo",
                              },
                            },
                            [
                              _c(
                                "a-spin",
                                { attrs: { spinning: _vm.wangeditorLoading2 } },
                                [
                                  _c(
                                    "a-upload-dragger",
                                    {
                                      attrs: {
                                        name: "file",
                                        accept: ".jpg,.png",
                                        showUploadList: false,
                                        action: _vm.ossUploadUrl,
                                        data: _vm.ossUploadData,
                                        "custom-request": _vm.handleUpload,
                                      },
                                      on: { change: _vm.handleUploadChange1 },
                                    },
                                    [
                                      !_vm.formData.processedImgUrl
                                        ? _c("div", [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "ant-upload-drag-icon",
                                              },
                                              [
                                                _c("a-icon", {
                                                  attrs: { type: "inbox" },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass: "ant-upload-text",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                拖拽或点击上传\n              "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass: "ant-upload-hint",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                支持JPG、PNG\n              "
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _c("img", {
                                            staticStyle: {
                                              "max-width": "300px",
                                              "max-height": "117px",
                                            },
                                            attrs: {
                                              src: _vm.formData.processedImgUrl,
                                              alt: "",
                                            },
                                          }),
                                      _vm.formData.processedImgUrl
                                        ? _c("a-icon", {
                                            attrs: { type: "close-circle" },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.clearUrl2.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm.visible
                                    ? _c("Editor", {
                                        ref: "editor",
                                        staticClass: "img-editor",
                                        attrs: {
                                          defaultConfig: _vm.editorConfig2,
                                          mode: _vm.mode,
                                        },
                                        on: {
                                          onCreated: _vm.onCreated2,
                                          customPaste: _vm.customPaste2,
                                        },
                                        model: {
                                          value: _vm.processedImgUrl,
                                          callback: function ($$v) {
                                            _vm.processedImgUrl = $$v
                                          },
                                          expression: "processedImgUrl",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.taskDetail.stageName == "制作" ||
                      _vm.taskDetail.stageName == "监制"
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                labelCol: _vm.labelCol,
                                wrapperCol: _vm.wrapperCol,
                                label: "字体",
                                prop: "tyoeface",
                              },
                            },
                            [
                              _c("a-input", {
                                attrs: { placeholder: "字体" },
                                model: {
                                  value: _vm.formData.typeface,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.formData, "typeface", $$v)
                                  },
                                  expression: "formData.typeface",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "back-content",
              on: { mouseover: _vm.topCenterMouseover },
            },
            [
              _c(
                "info-item",
                { attrs: { "label-width": _vm.labelWidth, label: "章节序号" } },
                [_vm._v(_vm._s(_vm.curRow.chapterNo))]
              ),
              _c("h4", { staticStyle: { "font-weight": "700" } }, [
                _vm._v("翻译、校对、终审填写："),
              ]),
              _c(
                "info-item",
                { attrs: { "label-width": _vm.labelWidth, label: "文本框" } },
                [
                  _vm._v(
                    _vm._s(
                      _vm.textAreaList.length && _vm.curRow.textAreaId
                        ? _vm.textAreaList.find(
                            (item) => item.id == _vm.curRow.textAreaId
                          ).label
                        : ""
                    )
                  ),
                ]
              ),
              _c(
                "info-item",
                { attrs: { "label-width": _vm.labelWidth, label: "原文图片" } },
                [
                  _c("div", [
                    _vm.curRow.originImgUrl
                      ? _c("img", {
                          staticStyle: {
                            "max-width": "100%",
                            "max-height": "180px",
                            cursor: "pointer",
                          },
                          attrs: {
                            src: _vm.curRow.originImgUrl,
                            alt: "avatar",
                          },
                          on: { click: _vm.handleImgClick },
                        })
                      : _vm._e(),
                  ]),
                ]
              ),
              _c(
                "info-item",
                {
                  staticStyle: {
                    "white-space": "pre-wrap",
                    "word-wrap": "break-word",
                  },
                  attrs: { "label-width": _vm.labelWidth, label: "原文" },
                },
                [_vm._v(_vm._s(_vm.curRow.originContent))]
              ),
              _c(
                "info-item",
                {
                  staticStyle: {
                    "white-space": "pre-wrap",
                    "word-wrap": "break-word",
                  },
                  attrs: { "label-width": _vm.labelWidth, label: "译文" },
                },
                [_vm._v(_vm._s(_vm.curRow.processedContent))]
              ),
              _vm.taskDetail.stageName == "制作" ||
              _vm.taskDetail.stageName == "监制"
                ? _c("h4", { staticStyle: { "font-weight": "700" } }, [
                    _vm._v("\n      制作、监制填写：\n    "),
                  ])
                : _vm._e(),
              _vm.taskDetail.stageName == "制作" ||
              _vm.taskDetail.stageName == "监制"
                ? _c(
                    "info-item",
                    {
                      attrs: { "label-width": _vm.labelWidth, label: "文件名" },
                    },
                    [_vm._v(_vm._s(_vm.curRow.fileName))]
                  )
                : _vm._e(),
              _vm.taskDetail.stageName == "制作" ||
              _vm.taskDetail.stageName == "监制"
                ? _c(
                    "info-item",
                    {
                      attrs: {
                        "label-width": _vm.labelWidth,
                        label: "制作后图片",
                      },
                    },
                    [
                      _c("div", [
                        _vm.curRow.processedImgUrl
                          ? _c("img", {
                              staticStyle: {
                                "max-width": "100%",
                                "max-height": "180px",
                                cursor: "pointer",
                              },
                              attrs: {
                                src: _vm.curRow.processedImgUrl,
                                alt: "avatar",
                              },
                              on: { click: _vm.handleImgClick },
                            })
                          : _vm._e(),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm.taskDetail.stageName == "制作" ||
              _vm.taskDetail.stageName == "监制"
                ? _c(
                    "info-item",
                    { attrs: { "label-width": _vm.labelWidth, label: "字体" } },
                    [_vm._v(_vm._s(_vm.curRow.typeface))]
                  )
                : _vm._e(),
            ],
            1
          ),
      _c("template", { slot: "footer" }, [
        _c(
          "div",
          { on: { mouseover: _vm.topCenterMouseover } },
          [
            _vm.isEdit
              ? _c(
                  "a-button",
                  {
                    key: "back",
                    on: {
                      click: function ($event) {
                        _vm.isEdit = false
                      },
                    },
                  },
                  [_vm._v("\n        取消\n      ")]
                )
              : _c(
                  "a-button",
                  { key: "back", on: { click: _vm.handleCancel } },
                  [_vm._v("\n        关闭\n      ")]
                ),
            _vm.isEdit
              ? _c(
                  "a-button",
                  {
                    key: "submit",
                    attrs: { type: "primary" },
                    on: { click: _vm.handleOk },
                  },
                  [_vm._v("\n        确定\n      ")]
                )
              : _c(
                  "a-button",
                  {
                    key: "submit",
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        _vm.isEdit = true
                      },
                    },
                  },
                  [_vm._v("\n        编辑\n      ")]
                ),
          ],
          1
        ),
      ]),
      _c("playvideo-modal", { ref: "videoModal" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }